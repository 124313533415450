import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakt"} />
		<Helmet>
			<title>
				Kontaktieren Sie uns | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler
			</title>
			<meta name={"description"} content={"Für weitere Informationen über Einschreibung, Bewerbung und den Tag der offenen Tür rufen Sie uns bitte an"} />
			<meta property={"og:title"} content={"Kontaktieren Sie uns | Sportschule - Entwicklungs- und Sportmöglichkeiten für junge Sportler"} />
			<meta property={"og:description"} content={"Für weitere Informationen über Einschreibung, Bewerbung und den Tag der offenen Tür rufen Sie uns bitte an"} />
			<meta property={"og:image"} content={"https://cubomuy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cubomuy.com/img/icon-man.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cubomuy.com/img/icon-man.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cubomuy.com/img/icon-man.png"} />
			<meta name={"msapplication-TileColor"} content={"https://cubomuy.com/img/icon-man.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-green" padding="80px 0 80px 0" color="--dark">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
				color="--dark"
			>
				<Text margin="0px 0px 30px 0px" font="--headline1" text-align="center" sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Kontaktinformationen{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" text-align="center" max-width="500px">
					Für weitere Informationen über Einschreibung, Bewerbung und den Tag der offenen Tür rufen Sie uns bitte an:
				</Text>
				<Link
					href="mailto:blank?admin@cubomuy.com"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					admin@cubomuy.com
				</Link>
				<Link
					href="tel:+49 355 752 50140"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+49 355 752 50140
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
					Berliner Straße 89, 03046 Cottbus, Deutschland
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});